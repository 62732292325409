<template>
  <div class="base-button">
    <v-btn
      :color="color"
      :dark="!disabled"
      class="radius-10"
      :class="classes"
      :width="width"
      :height="height"
      :type="type"
      :elevation="elevation"
      :outlined="outlined"
      :disabled="disabled"
      @click="$emit('click')"
    >
      <span class="mb-1 d-block" :class="spanClasses" v-if="!loading">{{ title }}</span>
      <span class="mt-2" v-if="loading"
        ><loading-spinner :isPrimary="outlined"></loading-spinner
      ></span>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "60px",
    },
    title: {
      type: String,
      default: "",
    },
    elevation: {
      type: String,
      default: "0",
    },
    classes: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    type: {
      type: String,
      default: "",
    },
    spanClasses: {
      type: String,
      default: "font-18 font-500 ",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    isPrimary: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.base-button {
  button {
    box-shadow: 0px 17px 25px 0px #fd7a5c0f !important;
  }
}
</style>
