import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    text: "",
    color: "",
    typeToast: "",
    timeout: "",
    loading: false,
    loadingLang: false,
    userLogged: true,
    fixedBtn: true,
    showNavbar: false,
    createBtn: true,
    translate: {},
    userDetails: {},
    favList: [],
    favListUpdated: false,
    token: "",
    changeLang: false,
    verifyOpen: false,
    verifyPhone: {
      phone: '',
      regionCode: ''
    },
    isChangePhone: false,
    reRenderUser: false,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    loadingLang(state) {
      return state.loadingLang;
    },
    typeToast(state) {
      return state.typeToast;
    },
    getLayoutPage(state) {
      return state.layoutPage;
    },
    userLogged(state) {
      return state.userLogged;
    },
    fixedBtn(state) {
      return state.fixedBtn;
    },
    showNavbar(state) {
      return state.showNavbar;
    },
    createBtn(state) {
      return state.createBtn;
    },
    translate(state) {
      return state.translate;
    },
    userDetails(state) {
      return state.userDetails;
    },
    favList(state) {
      return state.favList;
    },
    favListUpdated(state) {
      return state.favListUpdated;
    },
    token(state) {
      return state.token || localStorage.getItem("token");
    },
    changeLang(state) {
      return state.changeLang;
    },
    verifyOpen(state) {
      return state.verifyOpen;
    },
    verifyPhone(state) {
      return state.verifyPhone;
    },
    isChangePhone(state) {
      return state.isChangePhone;
    },
    reRenderUser(state) {
      return state.reRenderUser;
    },
  },
  mutations: {
    SHOW_MESSAGE(state, payload) {
      state.text = payload.text;
      state.color = payload.color;
      state.typeToast = payload.typeToast;
      state.timeout = payload.timeout;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_LOADING_LANG(state, payload) {
      state.loadingLang = payload;
    },
    SET_FIXEDBTN(state, payload) {
      state.fixedBtn = payload;
    },
    SET_SHOWNAVBAR(state, payload) {
      state.showNavbar = payload;
    },
    SHOW_CREATE_BTN(state, payload) {
      state.createBtn = payload;
    },
    SET_TRANSLATE(state, payload) {
      state.translate = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload.token;
      localStorage.setItem("token", payload.token);
    },
    REMOVE_USER(state) {
      state.token = "";
      state.userDetails = "";
      localStorage.removeItem("token");
      router.go();
    },
    SET_USER_DETAILS(state, payload) {
      state.userDetails = payload;
      state.favList = payload.favouriteActivities;
    },
    SET_FAV_LIST_SYNC(state, payload) {
      state.favList = payload;
      state.favListUpdated = !state.favListUpdated;
    },
    CHANGE_LANG(state) {
      state.changeLang = !state.changeLang;
    },
    VERIFY_PHONE(state, payload) {
      state.verifyOpen = payload.dialog;
      state.verifyPhone = payload.phone;
      state.isChangePhone = payload.isChange;
    },
    RE_RENDER_USER(state) {
      state.reRenderUser = !state.reRenderUser;
    },
  },
  actions: {
    showSnack({ commit }, payload) {
      commit("SHOW_MESSAGE", payload);
    },
    loading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
    loadingLang({ commit }, payload) {
      commit("SET_LOADING_LANG", payload);
    },
    fixedBtnHandler({ commit }, payload) {
      commit("SET_FIXEDBTN", payload);
    },
    showNavbarHandler({ commit }, payload) {
      commit("SET_SHOWNAVBAR", payload);
    },
    showCreateBtn({ commit }, payload) {
      commit("SHOW_CREATE_BTN", payload);
    },
    setTranslate({ commit }, payload) {
      commit("SET_TRANSLATE", payload);
    },
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);
    },
    async getUserDetails({ commit, getters }) {
      let res = await axios.get("/users/details", {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      });

      commit("SET_USER_DETAILS", res.data.value);
      return res.data.value;
    },
    logoutHandler({ commit }) {
      commit("REMOVE_USER");
    },
    changeLangHandler({ commit }) {
      commit("CHANGE_LANG");
    },
    verifyPhoneHandler({ commit }, payload) {
      commit("VERIFY_PHONE", payload);
    },
    reRenderUser({ commit }) {
      commit("RE_RENDER_USER");
    },
  },
  modules: {},
});
