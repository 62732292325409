<template>
  <div class="foot z-9 zoom-layout">
    <div
      class="footer-app"
      :class="isMd ? 'text-center' : ''"
      v-if="showFooter"
      :style="{
        marginTop:
          $route.name == 'footer-section' || $route.name == 'Reviews'
            ? '0px !important'
            : '',
      }"
    >
      <v-row class="mt-0 max-width px-10">
        <!-- first col -->
        <v-col cols="12" md="3">
          <router-link to="/about-us">
            <span
              class="d-block font-22 font-700 white--text footer.aboutBondai"
              >{{ translate["footer.aboutBondai"] }}</span
            >
          </router-link>
          <span
            class="d-block white--text font-18 mt-3 cursor-pointer footer.becomeProvider"
            :class="isMd ? 'font-700' : ''"
            @click="goTo('https://b2bondai.com/register')"
            >{{ translate["footer.becomePartner"] }}</span
          >

          <span
            class="d-block white--text font-18 mt-3 cursor-pointer footer.becomeProvider"
            :class="isMd ? 'font-700' : ''"
            @click="goTo('https://b2bondai.com/register')"
            >{{ translate["footer.becomeProvider"] }}</span
          >

          <span
            class="d-block white--text font-18 mt-3 cursor-pointer footer.faq"
            :class="isMd ? 'font-700' : ''"
            @click="$router.push('/'+$route.params.lang +'/faq')"
            >{{ translate["footer.faq"] }}</span
          >
        </v-col>

        <!-- second col -->
        <v-col cols="12" md="3">
          <span
            @click="goTo(`https://www.b2bondai.com?lang=${lang}`)"
            class="d-block font-22 font-700 white--text cursor-pointer"
            >{{ translate["footer.b2b"] }}</span
          >
          <span
            class="d-block white--text font-18 mt-3 cursor-pointer "
            :class="isMd ? 'font-700' : ''"
            @click="goTo(`https://bondaiportal.com/`)"
            >{{ translate["footer.saas"] }}</span
          >
          <router-link to="/terms-policy">
            <span
              class="d-block white--text font-18 mt-3"
              :class="isMd ? 'font-700' : ''"
              >{{ translate["footer.terms"] }}</span
            >
          </router-link>
          <router-link to="/privacy-policy">
            <span
              class="d-block white--text font-18 mt-3 "
              :class="isMd ? 'font-700' : ''"
              >{{ translate["footer.policy"] }}</span
            >
          </router-link>
          <router-link to="/refund-policy">
            <span
              class="d-block white--text font-18 mt-3 "
              :class="isMd ? 'font-700' : ''"
              >{{ translate["footer.refund"] }}</span
            >
          </router-link>
        </v-col>
        <!-- desc & socials -->
        <v-col cols="12" md="3" offset-md="3">
          <span
            class="d-block white--text font-18 px-2 mt-1 footer.followUs"
            v-if="!isMd"
            >{{ translate["footer.followUs"] }}</span
          >
          <div
            class="d-flex align-center w-100 mt-3 px-1"
            :class="isMd ? ' justify-center' : ''"
          >
            <div
              class="mx-1 radius-10 d-flex align-center cursor-pointer"
              v-for="(social, i) in socials"
              :key="i"
              @click="goTo(social.link)"
            >
              <img width="28" height="28" :src="social.icon" :alt="social.type" />
            </div>
          </div>
          <span class="d-block white--text font-14 mt-4 cursor-pointer mx-2">{{
            translate["footer.contactPhone"]
          }}</span>
          <span
            class="d-block white--text font-14 mt-2 cursor-pointer mx-2"
            @click="phoneClicked('+966555074410')"
            >{{ translate["footer.contactPhone1"] }}</span
          >
          <span
            class="d-block white--text font-14 mt-1 cursor-pointer mx-2"
            @click="phoneClicked('+966555074439')"
            >{{ translate["footer.contactPhone2"] }}</span
          >
          <div
            class="d-flex align-center"
            :class="isMd ? 'justify-center' : ''"
          >
            <span class="d-block white--text font-14 mt-2 mx-2"
              >{{ translate["footer.email"] }}:
            </span>
            <span
              class="d-block white--text font-14 mt-2 cursor-pointer"
              @click="mailClicked('hi@bondai.io')"
              >hi@bondai.io</span
            >
          </div>
        </v-col>

        <v-col cols="12" class="pb-0">
          <span class="d-block white--text font-16 mt-2">
            <span class="font-500">{{ $t("footer.registerNumber") }}: </span>
            311391775800003
          </span>
          <span class="d-block white--text font-16 mt-2">
            <span class="font-500"> {{ $t("footer.eCR") }}: </span> 4030480178
          </span>
          <span class="d-block white--text font-16 mt-2">
            <span class="font-500"> {{ $t("footer.ksa") }}:</span> 73104060
          </span>
        </v-col>
      </v-row>
    </div>
    <div class="copyright py-5 " :class="isMd ? 'text-center' : ''">
      <div class="max-width px-13">
        <span class="d-block white--text font-18">{{
          $t("footer.copyRight1") + yearDate + $t("footer.copyRight2")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    socials: [
      {
        icon: require("@/assets/images/social/twitter.svg"),
        link: "https://twitter.com/bondai_io",
        type: "twitter",
      },
      {
        icon: require("@/assets/images/social/insta.svg"),
        link: "https://www.instagram.com/bondai.io/",
        type: "insta",
      },
      {
        icon: require("@/assets/images/social/linkedin.svg"),
        link: "https://www.linkedin.com/company/bondai-io",
        type: "linkedin",
      },
      {
        icon: require("@/assets/images/social/tiktok.svg"),
        link: "https://www.tiktok.com/@bondai.io",
        type: "tiktok",
      },
      {
        icon: require("@/assets/images/social/facebook.svg"),
        link: "https://www.facebook.com/Bondai.io/",
        type: "facebook",
      },
      {
        icon: require("@/assets/images/social/whatsapp.svg"),
        link: "http://web.whatsapp.com/send?phone=+966555074410",
        type: "whatsapp",
      },
    ],
    contactDialog: false,
  }),
  computed: {
    ...mapGetters(["translate"]),
    showFooter() {
      if (
        this.$route.name == "error-page" ||
        this.$route.name == "server-error" ||
        this.$route.name == "Adventure Complete"
      ) {
        return false;
      } else {
        return true;
      }
    },
    yearDate() {
      return new Date().getFullYear();
    },
    lang() {
      return localStorage.getItem("language");
    },
  },
  methods: {
    goTo(link) {
      window.open(link);
    },
    phoneClicked(tel) {
      window.open("tel:" + tel);
    },
    mailClicked(mail) {
      window.open(`mailto:${mail}?subject=subject&body=body`);
    },
    // linkHandler() {
    //   if (this.$route.name == "Homepage") {
    //     const top = document.querySelector("#CREATE-TRIP");
    //     window.scrollTo({
    //       top: top.offsetTop - 80,
    //       left: 0,
    //       behavior: "smooth",
    //     });
    //   } else {
    //     this.$router.push("/create-your-trip");
    //   }
    // },
    contactHandler() {
      if (this.$route.name == "Homepage" || this.$route.name == "Contact") {
        const top = document.querySelector("#CONTACT");
        window.scrollTo({
          top: top.offsetTop - 80,
          left: 0,
          behavior: "smooth",
        });
      } else {
        this.contactDialog = true;
      }
    },
  },
};
</script>

<style lang="scss">
.footer {
  .footer-app {
    padding: 50px 0px 30px 0px;
    background: #1f2227;
    z-index: 8;
  }
  .copyright {
    background: #2e333b;
  }
}
</style>
