<template>
  <div>
    <v-menu v-if="!isMd" offset-y bottom>
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center mx-1 cursor-pointer"
          v-bind="attrs"
          v-on="on"
        >
          <span class="font-14 d-inline-block mx-1 darkblue2--text">{{
            $t("global.bondaiGroup")
          }}</span>
          <img
            class="mx-2"
            width="12px"
            height="12px"
            :src="require(`@/assets/images/icons/arrow-down.svg`)"
          />
        </div>
      </template>

      <v-list :elevation="0">
        <v-list-item v-for="(link, i) in links" :key="i" class="mb-3 mt-2">
          <a class="d-flex align-start" :href="link.link">
            <img
              :style="{ top: link.top }"
              width="33px"
              class="mx-1 relative"
              :src="link.img"
            />
            <v-list-item-title class="mx-3">
              <div class="font-16 font-600 mb-1 black--text">
                {{ link.title }}
              </div>
              <div class="font-12 black--text">
                {{ translate[link.subTitle] }}
              </div>
            </v-list-item-title>
          </a>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- languages mobile -->
    <v-list-group v-if="isMd" no-action class="px-0">
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>
            <div class="d-flex align-center">
              <span class="black--text font-14 d-inline-block">{{
                $t("global.bondaiGroup")
              }}</span>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="(link, i) in links"
        :key="i"
        @click="goToLink(link.link)"
        class="px-5 mb-1 d-flex align-start"
      >
        <img
          style="margin-top: 6px"
          :class="$vuetify.rtl ? 'ml-2' : 'mr-2'"
          width="23px"
          :src="link.img"
        />
        <v-list-item-title>
          <div class="font-16 font-600">{{ link.title }}</div>
          <div class="font-12">{{ translate[link.subTitle] }}</div>
        </v-list-item-title>
      </v-list-item>
    </v-list-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    links: [
      {
        title: "B2Bondai.com",
        img: require("@/assets/images/global/b2b-icon.svg"),
        subTitle: "navbar.B2BondaiSubTitle",
        link: "https://b2bondai.com/",
        top: '6px'
      },
      {
        title: "Ksabeds.com",
        img: require("@/assets/images/global/mini-logo-ksa.png"),
        subTitle: "navbar.KsabedsSubTitle",
        link: "https://ksa-beds.com/",
        top: '6px'

      },
      {
        title: "BondaiPortal.com",
        img: require("@/assets/images/global/portal-icon.svg"),
        subTitle: "navbar.BondaiPortalSubTitle",
        link: "https://bondaiportal.com/",
        top: '6px'

      },
      {
        title: "Bondaigroup.com",
        img: require("@/assets/images/global/min-logo-holdings.svg"),
        subTitle: "navbar.BondaigroupSubTitle",
        link: "https://bondaigroup.com/",
        top: '8px'
      },
    ],
  }),
  computed: {
    ...mapGetters(["translate"]),
  },
  methods: {},
};
</script>

<style lang="scss">
.group-menu {
  top: -40px;
  right: -30px;
  width: max-content;
  z-index: 999;
}
</style>
