<template>
  <div class="drawer">
    <v-app-bar
      prominent
      height="80px"
      class="w-100 d-flex align-center justify-space-between drawer-navbar drawer-navbar--scrolled"
      elevation="0"
    >
      <router-link to="/">
        <img :src="require(`@/assets/images/global/logo.svg`)" />
      </router-link>
      <transition name="fade" mode="out-in">
        <v-app-bar-nav-icon
          style="z-index: 999999"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
      </transition>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      :right="$i18n.locale == 'en'"
      :left="$i18n.locale != 'en'"
      height="100vh"
    >
      <v-list class="px-3" nav dense v-model="menuGroup">
        <v-list-item-group v-model="group">
          <div class="d-flex justify-end text-right my-2 cursor-pointer">
            <img
              @click.stop="drawer = !drawer"
              :src="require('@/assets/images/icons/close.svg')"
            />
          </div>
          <router-link to="/">
            <img :src="require(`@/assets/images/global/logo.svg`)" />
          </router-link>

          <!-- settings -->
          <v-list-item
            v-for="(link, i) in links"
            :key="i"
            @click="linkClicked(link)"
          >
            <span class="font-16 black--text" :class="translate[link.title]">{{
              translate[link.title]
            }}</span>
          </v-list-item>
          <bondai-groups></bondai-groups>

          <!-- list group -->
          <Languages
            @switchLanguage="switchLanguage"
            :selectedLanguage="selectedLanguage"
          ></Languages>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- login form -->
    <v-dialog
      v-model="loginDialog"
      max-width="1100px"
      height="600px"
      content-class="radius-25 form-height white"
    >
      <login-form @close="loginDialog = false"></login-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoginForm from "../components/dialogs/LoginForm.vue";
import BondaiGroups from "./components/BondaiGroups.vue";
import Languages from "./components/Languages.vue";
import { updateUserDetails } from "@/services/User";

export default {
  components: { LoginForm, Languages,  BondaiGroups },
  data: () => ({
    menuGroup: "group",
    drawer: false,
    settingDialog: false,
    group: "",
    loginDialog: false,
    loggedOut: false,
  }),
  computed: {
    ...mapGetters(["showNavbar", "translate", "userDetails", "token"]),
    links() {
      return [
        // {
        //   id: 3,
        //   title: "navbar.blogs",
        //   name: "blogs",
        //   route: "/blogs",
        //   active: false,
        // },
        {
          id: 4,
          title: "navbar.contactUs",
          name: "contactUs",
          route: "/" + this.$route.params.lang + "/contact-us",
          active: false,
        },
        {
          id: 6,
          title: "navbar.aboutUs",
          name: "aboutUs",
          route: "/" + this.$route.params.lang + "/about-us",
          active: false,
        },
      ];
    },
    isHomepage() {
      if (this.$route.name == "Homepage") {
        return true;
      } else {
        return false;
      }
    },
    selectedLanguage() {
      if (this.$vuetify.rtl) {
        return "arabic";
      } else {
        if (localStorage.getItem("language") == "en") {
          return "english";
        } else {
          return "chinese";
        }
      }
    },
  },
  methods: {
    logoutHandler() {
      this.$store.dispatch("logoutHandler");
    },
    loginHandler() {
      this.loggedOut = false;
      this.loginDialog = true;
    },
    settingHandler() {
      this.drawer = false;
      this.settingDialog = true;
    },
    goToLink(link) {
      window.open(link, "_self");
    },
    async switchLanguage(language) {
      this.selectedLanguage = language.title;
      localStorage.setItem("language", language.value);
      this.$vuetify.rtl = language.value == "ar" ? true : false;
      this.$i18n.locale = language.value;
      if (this.token) {
        let reqObj = {
          globalPreferences: {
            languageCode: language.value,
          },
        };
        await updateUserDetails(reqObj);
      }
      this.$router.push({
        name: this.$route.name,
        params: { lang: language.value },
      });
      this.$store.dispatch("changeLangHandler");
    },
    linkClicked(link) {
      if (link.name == "create-trip") {
        const top = document.querySelector("#CREATE-TRIP");
        window.scrollTo({
          top: top.offsetTop - 80,
          left: 0,
          behavior: "smooth",
        });
      } else {
        this.$router.push("/" + this.$route.params.lang + "/" + link.route);
      }
    },
  },
};
</script>

<style lang="scss">
.v-toolbar__content {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100%;
}
.drawer {
  z-index: 9999999999999999999 !important;
  position: relative;

  .drawer-navbar {
    background: transparent !important;
    transition: 0.3s;

    &--scrolled {
    }
  }
  .logo {
    width: 110px !important;
  }
  .v-list {
    background: transparent !important;
    i {
      color: white;
    }
  }
  .v-list-group__header {
    padding-left: 8px !important;
    i {
      color: white !important;
    }
  }
  .primary-outlined {
    border: 1px solid $primary;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }
}
</style>
