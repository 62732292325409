const routes = [
  {
    path: "/:lang",
    name: "Homepage",
    component: () => import("../views/homepage/Index.vue"),
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:lang/services",
    name: "Service",
    component: () => import("../views/all-services/Index"),
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:lang/service/:slug",
    name: "Service Slug",
    component: () => import("../views/all-services/id/Index"),
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:lang/services/:slug/checkout",
    name: "Checkout",
    component: () => import("../views/all-services/checkout/Index"),
    meta: { requiresAuth: true },
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:lang/payment-gateway/:token",
    name: "Payment-gateway",
    component: () => import("../views/all-services/checkout/Index"),
    meta: { requiresAuth: true },
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:lang/booking-summary/:id",
    name: "Booking-summary",
    component: () => import("../views/all-services/booking-summary/Index"),
    meta: { requiresAuth: true },
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:lang/dashboard/",
    name: "Dashboard",
    component: () => import("../views/dashboard/Index"),
    pathToRegexpOptions: { strict: true },
    meta: { requiresAuth: true },

    children: [
      {
        path: "overview",
        name: "Overview",
        component: () => import("../views/dashboard/pages/Overview"),
        meta: { requiresAuth: true },
      },
      {
        path: "bookings",
        name: "Bookings",
        component: () => import("../views/dashboard/pages/Bookings"),
        meta: { requiresAuth: true },
      },
      {
        path: "profile",
        name: "Profile",
        component: () => import("../views/dashboard/pages/Profile"),
        meta: { requiresAuth: true },
      },
      {
        path: "favorites",
        name: "Favorites",
        component: () => import("../views/dashboard/pages/Favorites"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/:lang/refund-policy",
    name: "Refund-Policy",
    component: () => import("../views/static/Refund"),
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:lang/terms-policy",
    name: "Terms-Policy",
    component: () => import("../views/static/Terms"),
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:lang/cookies-policy",
    name: "Cookies-Policy",
    component: () => import("../views/static/Cookies"),
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:lang/privacy-policy",
    name: "Privacy-Policy",
    component: () => import("../views/static/Privacy"),
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:lang/faq",
    name: "FAQ",
    component: () => import("../views/faq/Index"),
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:lang/contact-us",
    name: "Contact-us",
    component: () => import("../views/contact/Index"),
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:lang/500",
    name: "500",
    component: () => import("../views/500/Index"),
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:lang/review/:token",
    name: "Review",
    component: () => import("../views/reviews/Index"),
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:lang/register",
    name: "Register",
    component: () => import("../views/register/Index"),
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:lang/reset-password/*",
    name: "Reset-Password",
    component: () => import("../views/reset-password/Index"),
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:lang/confirm-email-address/:token",
    name: "Confirm-Email-Address",
    component: () => import("../views/confirm-email/Index"),
    pathToRegexpOptions: { strict: true },
  },
  // {
  //   path: "/:lang/*",
  //   name: "error-page",
  //   component: () => import("../views/not-found/Index"),
  //   pathToRegexpOptions: { strict: true },
  // },
];

export default routes;
