<template>
  <div>
    <v-menu
      offset-y
      bottom
      left
      content-class="language-menu"
      absolute
      min-width="150px"
      v-if="!isMd"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center mx-3" v-bind="attrs" v-on="on">
          <img :src="require(`@/assets/images/icons/users.svg`)" alt="" />
          <span class="font-14 mx-2 black--text" v-if="token">{{
            userDetails.firstName
          }}</span>
        </div>
      </template>
      <v-list class="user-menu radius-10" :elevation="0">
        <v-list-item
          v-for="(link, i) in menu"
          :key="i"
          @click="goToLink(link.link)"
          class="cursor-pointer custom-list-item"
          style="width: 250px"
        >
          <img :src="link.icon" alt="" />
          <v-list-item-title class="cursor-pointer font-14 mx-2">{{
            translate[link.title]
          }}</v-list-item-title>
        </v-list-item>

        <div class="line my-1"></div>

        <v-list-item class="cursor-pointer" @click="logoutHandler">
          <div class="d-flex align-center justify-center custom-list-item">
            <img :src="require('@/assets/images/dashboard/icons/logout.svg')" />
            <span class="black--text mx-2">
              {{ translate["navbar.logout"] }}
            </span>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- user menu mobile -->
    <v-list-group v-if="isMd" no-action class="px-0">
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>
            <div class="d-flex align-center">
              <img :src="require(`@/assets/images/icons/users.svg`)" alt="" />
              <span class="font-22 black--text mx-2">{{
                userDetails.firstName
              }}</span>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="(link, i) in menu"
        :key="i"
        @click="goToLink(link.link)"
        class="cursor-pointer px-6 custom-list-item"
      >
        <img class="mr-2" width="24px" :src="link.icon" alt="menu icon" />

        <v-list-item-title class="cursor-pointer font-14">{{
          translate[link.title]
        }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapGetters(["translate", "userDetails", "token"]),
    menu() {
      return [
        {
          title: "navbar.overview",
          icon: require("@/assets/images/dashboard/icons/overview.svg"),
          link: "/" + this.$route.params.lang + "/dashboard/overview",
        },
        {
          title: "navbar.bookings",
          icon: require("@/assets/images/dashboard/icons/ticket.svg"),

          link: "/" + this.$route.params.lang + "/dashboard/bookings",
        },
        {
          title: "navbar.favorites",
          icon: require("@/assets/images/dashboard/icons/fav.svg"),
          link: "/" + this.$route.params.lang + "/dashboard/favorites",
        },
        {
          title: "navbar.myProfile",
          icon: require("@/assets/images/dashboard/icons/user.svg"),
          link: "/" + this.$route.params.lang + "/dashboard/profile",
        },

        // {
        //   title: "navbar.paymentMethods",
        //   icon: require("@/assets/images/dashboard/icons/credit-card.svg"),
        //   link: "/dashboard/payment-methods",
        // },
      ];
    },
  },
  methods: {
    logoutHandler() {
      this.$emit("loggedOut", true);
    },
    goToLink(url) {
      window.open(url, "_self");
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
.v-list-item {
  min-height: 40px !important;
  height: 40px !important;
}
.custom-list-item {
  i {
    &::before {
      display: inline-block;
    }
  }
}
</style>
