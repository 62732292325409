<template>
  <div class="navbar navbar--white zoom-layout">
    <drawer-app :links="links" v-if="isMd"></drawer-app>

    <div
      class="navbar__max-width mt-4 mb-2 hidden-sm-and-down z-9 d-flex align-center justify-space-between"
      v-if="!isMd"
    >
      <div class="d-flex align-center h-100 navbar__logo" v-if="!$vuetify.rtl">
        <!-- logo -->
        <router-link :to="'/' + $route.params.lang">
          <img
            class="mt-3"
            :src="require(`@/assets/images/global/logo.svg`)"
            alt="bondai logo"
          />
        </router-link>
      </div>
      <!-- buttons logged -->
      <transition name="fade" mode="out-in">
        <div class="w-100 d-flex align-center justify-end">
          <bondai-groups></bondai-groups>


          <!-- languages -->
          <languages
            :selectedLanguage="selectedLanguage"
            @switchLanguage="switchLanguage"
          ></languages>

          <!-- login -->
          <!-- <div
            class="d-flex align-center mx-2 cursor-pointer primary-outline"
            @click="loginHandler"
            v-if="!token"
          >
            <span class="font-14 d-inline-block primary--text">{{
              translate["form.login"]
            }}</span>
          </div> -->

          <user-menu
            class="d-flex align-center cursor-pointer"
            v-if="token"
            @loggedOut="logoutHandler"
          ></user-menu>

          <!-- logo -->
          <div class="navbar__logo" v-if="$vuetify.rtl">
            <router-link to="/">
              <img
                class="mt-3"
                :src="require(`@/assets/images/global/logo.svg`)"
                alt="bondai logo"
              />
            </router-link>
          </div>
        </div>
      </transition>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Languages from "./components/Languages.vue";
import BondaiGroups from "./components/BondaiGroups.vue";
import UserMenu from "./components/UserMenu.vue";
import DrawerApp from "./Drawer.vue";
import { updateUserDetails } from "@/services/User";

export default {
  components: {
    DrawerApp,
    Languages,
    BondaiGroups,
    UserMenu,
  },
  data: () => ({
    links: [],
    obj: {},
    settingDialog: false,
    loginDialog: false,
    type: "login",
  }),
  watch: {
    $route: {
      async handler() {
        if (this.token && !this.userDetails) {
          await this.$store.dispatch("getUserDetails");
        }
      },
    },
  },
  computed: {
    ...mapGetters([
      "showNavbar",
      "createBtn",
      "translate",
      "userDetails",
      "token",
    ]),
    userLogged() {
      return this.$store.getters["userLogged"];
    },
    blurNav() {
      if (
        this.$route.name == "Blog" ||
        this.$route.name == "Custom Blog" ||
        this.$route.name == "About"
      ) {
        return true;
      } else {
        return false;
      }
    },
    selectedLanguage() {
      if (this.$vuetify.rtl) {
        return "arabic";
      } else {
        if (localStorage.getItem("language") == "en") {
          return "english";
        } else {
          return "chinese";
        }
      }
    },
  },
  methods: {
    logoutHandler() {
      this.$store.dispatch("logoutHandler");
    },
    loginHandler() {
      this.loginDialog = true;
      this.type = "login";
    },
    async switchLanguage(language) {
      this.selectedLanguage = language.title;
      localStorage.setItem("language", language.value);
      this.$vuetify.rtl = language.value == "ar" ? true : false;
      this.$i18n.locale = language.value;
      if (this.token) {
        let reqObj = {
          globalPreferences: {
            languageCode: language.value,
          },
        };
        this.$store.dispatch("loadingLang", true);
        await updateUserDetails(reqObj);
        this.$store.dispatch("loadingLang", false);
      }
      // Inside a component or method
      const currentParams = this.$route.params;
      const newParams = { ...currentParams, lang: language.value };

      // Check if the new parameters are different from the current ones
      if (JSON.stringify(newParams) !== JSON.stringify(currentParams)) {
        this.$router.replace({
          name: this.$route.name,
          params: { lang: language.value },
        });
      }
      this.$store.dispatch("changeLangHandler");
    },
    goToLink(link) {
      window.open(link, "_self");
    },
    linkHandler(link) {
      if (link.name == "Group-Trip") {
        window.open("https://tickets.bondai.io/calendar", "_self");
      }
      if (link.name == "Create-Trip") {
        if (this.$route.name == "Homepage") {
          const top = document.querySelector("#CREATE-TRIP");
          window.scrollTo({
            top: top.offsetTop - 80,
            left: 0,
            behavior: "smooth",
          });
        } else {
          this.$router.push(
            "/" + this.$route.params.lang + "/create-your-trip"
          );
        }
      }
    },
  },
  async created() {
    if (this.token) {
      await this.$store.dispatch("getUserDetails");
    }
  },
};
</script>

<style lang="scss">
.navbar {
  position: fixed;
  top: 0px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  z-index: 99999999;
  transition: 0.3s;
  height: 80px;
  &__max-width {
    max-width: 1500px;
    margin: auto;
    padding: 0px 2%;
    position: relative;
  }
  &--scrolled {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #4e6d86, rgba(145, 180, 208, 0.1));

    border-bottom: 0.5px solid #ffffff81;
    backdrop-filter: blur(2px);
  }
  &--white {
    border-bottom: 1px solid #dbdde0;
    background: white;
  }
  .language-menu {
    z-index: 9999999 !important;
    span {
      color: white;
      transition: 0.3s;
    }
  }
  &__links {
    border-radius: 18px;
    span {
      transition: 0.3s;
    }

    &__link {
      transition: 0.3s;
      opacity: 1;

      &:hover {
        opacity: 0.8;
      }
      &--active {
        opacity: 0.8;
      }
    }
  }

  &__logo {
    height: 50px;
    transition: 0.3s;
  }

  .white-outline {
    border: 1px solid white;
    border-radius: 10px;
    padding: 6px 20px;
  }
  .primary-outline {
    border: 1px solid $primary;
    border-radius: 10px;
    padding: 6px 20px;
  }
}
.v-menu__content {
  z-index: 9999999999999999999999 !important;
}
.language-menu {
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transform: translate(24px, 18px) !important;
  z-index: 999 !important;
  .v-list-item {
    min-height: 35px;
  }
}
.form-height {
  height: 600px !important;
}

.v-tooltip__content {
  z-index: 999999999999999 !important;
}
</style>
